// Generated by Framer (b12dec4)

import { addFonts, cx, CycleVariantState, SVG, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["VsLH0DLnA"];

const variantClassNames = {VsLH0DLnA: "framer-v-hgwy1i"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "VsLH0DLnA", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-QhOqE", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-hgwy1i", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"VsLH0DLnA"} ref={ref} style={{...style}}><SVG className={"framer-da07pl"} layout={"position"} layoutDependency={layoutDependency} layoutId={"JrOj95SsV-shape"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 64 64\"><path d=\"M 32.032 0 C 14.344 0 0 14.336 0 31.989 C 0 45.148 8.083 56.959 20.347 61.752 C 19.918 58.693 19.961 55.611 20.454 52.573 C 21.033 50.069 24.206 36.675 24.206 36.675 C 23.563 35.177 23.241 33.572 23.263 31.946 C 23.263 27.495 25.835 24.179 29.073 24.179 C 31.303 24.136 33.125 25.912 33.168 28.137 C 33.168 28.33 33.168 28.501 33.147 28.694 C 33.147 31.433 31.41 35.541 30.488 39.349 C 29.845 41.832 31.367 44.356 33.854 44.998 C 34.304 45.105 34.776 45.17 35.226 45.127 C 40.929 45.127 45.303 39.135 45.303 30.491 C 45.539 23.537 40.072 17.695 33.082 17.46 C 32.696 17.439 32.289 17.46 31.903 17.482 C 24.249 17.139 17.774 23.066 17.431 30.684 C 17.431 30.898 17.409 31.112 17.409 31.347 C 17.409 33.957 18.246 36.525 19.789 38.643 C 20.025 38.879 20.089 39.242 20.004 39.563 C 19.768 40.569 19.21 42.752 19.103 43.201 C 18.975 43.779 18.632 43.907 18.031 43.629 C 14.022 41.767 11.513 35.947 11.513 31.24 C 11.513 21.162 18.846 11.897 32.675 11.897 C 43.781 11.897 52.421 19.343 52.421 30.363 C 52.421 41.382 45.453 50.262 35.805 50.262 C 32.889 50.369 30.124 48.978 28.473 46.582 L 26.457 54.178 C 25.514 57.131 24.163 59.955 22.469 62.565 C 39.386 67.786 57.331 58.307 62.563 41.425 C 67.794 24.543 58.296 6.633 41.38 1.412 C 38.357 0.492 35.205 0 32.032 0 Z\" fill=\"rgba(1,2,2,1)\"></path></svg>"} svgContentId={4125449469} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-QhOqE [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-QhOqE .framer-4mt5zf { display: block; }", ".framer-QhOqE .framer-hgwy1i { height: 64px; overflow: hidden; position: relative; width: 64px; }", ".framer-QhOqE .framer-da07pl { flex: none; height: 64px; left: calc(50.00000000000002% - 64px / 2); position: absolute; top: calc(50.00000000000002% - 64px / 2); width: 64px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 64
 * @framerIntrinsicWidth 64
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables false
 */
const FramerNHDgZiT3H: React.ComponentType<Props> = withCSS(Component, css, "framer-QhOqE") as typeof Component;
export default FramerNHDgZiT3H;

FramerNHDgZiT3H.displayName = "Social Buttons / Pinterest";

FramerNHDgZiT3H.defaultProps = {height: 64, width: 64};

addFonts(FramerNHDgZiT3H, [])